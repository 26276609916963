import { Switch } from "@headlessui/react";
import { ExclamationIcon } from "@heroicons/react/solid";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function CounterBadge(props) {
  return (
    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 mr-2">
      {props.count}
    </span>
  );
}

function SwitchRG90(props) {
  const [enabled, setEnabled] = useState(false);

  function handleChange(value) {
    setEnabled(value);
    props.onChange(value);
  }

  return (
    <Switch.Group as="div" className="flex items-center justify-between">
      <span className="flex-grow flex flex-col">
        <Switch.Label
          as="span"
          className="text-sm font-medium text-gray-900"
          passive
        >
          Validar archivo Marangatu RG90
        </Switch.Label>
        <Switch.Description as="span" className="text-sm text-gray-500">
          Marque esta opción para validar un archivo de tipo Marangatu RG90.
        </Switch.Description>
      </span>
      <Switch
        checked={enabled}
        onChange={(v) => handleChange(v)}
        className={classNames(
          enabled ? "bg-indigo-600" : "bg-gray-200",
          "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            enabled ? "translate-x-5" : "translate-x-0",
            "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
          )}
        />
      </Switch>
    </Switch.Group>
  );
}

function InputElement(props) {
  const indexRef = useRef(null);
  const [files, setFiles] = useState([]);
  const [rg90, setRg90] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
  });

  function clickHander() {
    props.handler({ index: indexRef.current.value, files: files, rg90: rg90 });
  }

  function onChangeRg90Handler(value) {
    if (value) {
      indexRef.current.value = 3;
      indexRef.current.disabled = true;
    } else {
      indexRef.current.value = "";
      indexRef.current.disabled = false;
    }
    setRg90(value);
  }

  return (
    <>
      <SwitchRG90 onChange={(v) => onChangeRg90Handler(v)}></SwitchRG90>

      <div className="sm:items-start sm:pt-5 sm:flex">
        <div className="mt-1 sm:mt-0 flex-grow">
          <input
            type="number"
            min="1"
            step="1"
            ref={indexRef}
            name="index"
            id="index"
            placeholder="Columna del RUC"
            className="w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
          />
          <p className="mt-2 text-sm text-gray-500">
            Indique en que número de columna del archivo se encuentra el RUC,
            Ej: 1. (El ruc no debe poseer el dígito verificador)
          </p>
        </div>
      </div>

      <div className="sm:items-start sm:pt-5">
        <div
          {...getRootProps()}
          className="flex justify-center items-center w-full"
        >
          <label
            htmlFor="file"
            onClick={(e) => e.preventDefault()}
            className="flex flex-col justify-center items-center w-full h-64 bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer hover:bg-gray-100"
          >
            <div className="flex flex-col justify-center items-center pt-5 pb-6">
              {isDragActive || files.length > 0 ? (
                <p className="text-xs text-gray-500">
                  {isDragActive
                    ? "Suelta aquí..."
                    : files.map((file) => (
                        <span key={file.path} className="text-lg">
                          {file.path}
                        </span>
                      ))}
                </p>
              ) : (
                <>
                  <svg
                    aria-hidden="true"
                    className="mb-3 w-10 h-10 text-gray-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                    ></path>
                  </svg>
                  <p className="mb-2 text-sm text-gray-500">
                    <span className="font-semibold">Click para subir</span> o
                    arrastra y suelta el archivo.
                  </p>
                  <p className="text-xs text-gray-500">EXCEL, CSV o TXT</p>
                </>
              )}
            </div>
            <input
              {...getInputProps()}
              id="file"
              type="file"
              name="file"
              className="hidden"
            />
          </label>
        </div>
      </div>

      <div>
        <button
          type="button"
          onClick={clickHander}
          disabled={props.working}
          className="relative w-full inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          {props.working ? "Validando espere..." : "Validar"}
        </button>
      </div>
      <div>
        <p className="text-sm mb-5 text-gray-500">
          Descargo de responsabilidad: La validación se basa en la última
          publicación de RUC y equivalencias de la SET, puede que contribuyentes
          inscriptos posterior a la publicación no sean contemplados.
        </p>
      </div>
    </>
  );
}

function OutputElement(props) {
  const [filters, setFilters] = useState({
    "RUC INEXISTENTE": true,
    "SUSPENSION TEMPORAL": false,
    CANCELADO: false,
    BLOQUEADO: false,
  });

  let filterList = [];
  let data = [];

  Object.keys(filters).forEach(function (item) {
    if (filters[item]) filterList.push(item);
  });

  data = props.data.filter(function (el) {
    return filterList.includes(el.msg);
  });

  function handleChangeFilter(el) {
    const { name, value } = el.target;
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="flex">
        <div>
          <input
            id="inexistente-checkbox"
            type="checkbox"
            checked={filters["RUC INEXISTENTE"] ? "checked" : ""}
            value=""
            className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 focus:ring-2"
            onChange={(e) => {
              handleChangeFilter({
                target: {
                  name: "RUC INEXISTENTE",
                  value: e.target.checked,
                },
              });
            }}
          />
          <label
            htmlFor="inexistente-checkbox"
            className="ml-2 text-sm font-medium text-gray-900"
          >
            Ruc Inexistente{" "}
            <CounterBadge count={props.counters["RUC INEXISTENTE"]} />
          </label>
        </div>
        <div className="ml-4">
          <input
            id="suspension-checkbox"
            type="checkbox"
            checked={filters["SUSPENSION TEMPORAL"] ? "checked" : ""}
            value=""
            className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 focus:ring-2"
            onChange={(e) => {
              handleChangeFilter({
                target: {
                  name: "SUSPENSION TEMPORAL",
                  value: e.target.checked,
                },
              });
            }}
          />
          <label
            htmlFor="suspension-checkbox"
            className="ml-2 text-sm font-medium text-gray-900"
          >
            Suspensión Temporal{" "}
            <CounterBadge count={props.counters["SUSPENSION TEMPORAL"]} />
          </label>
        </div>
        <div className="ml-4">
          <input
            id="cancelado-checkbox"
            type="checkbox"
            checked={filters["CANCELADO"] ? "checked" : ""}
            value=""
            className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 focus:ring-2"
            onChange={(e) => {
              handleChangeFilter({
                target: {
                  name: "CANCELADO",
                  value: e.target.checked,
                },
              });
            }}
          />
          <label
            htmlFor="cancelado-checkbox"
            className="ml-2 text-sm font-medium text-gray-900"
          >
            Cancelado <CounterBadge count={props.counters["CANCELADO"]} />
          </label>
        </div>
        <div className="ml-4">
          <input
            id="bloqueado-checkbox"
            type="checkbox"
            checked={filters["BLOQUEADO"] ? "checked" : ""}
            value=""
            className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 focus:ring-2"
            onChange={(e) => {
              handleChangeFilter({
                target: {
                  name: "BLOQUEADO",
                  value: e.target.checked,
                },
              });
            }}
          />
          <label
            htmlFor="bloqueado-checkbox"
            className="ml-2 text-sm font-medium text-gray-900"
          >
            Bloqueado <CounterBadge count={props.counters["BLOQUEADO"]} />
          </label>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Fila
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Ruc
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Estado
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Analizado
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {data.map((row) => (
                    <tr key={row.row}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {row.row}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {row.ruc}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-red-100 text-red-800">
                          {row.msg}
                        </span>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {row.data}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ErrorElement(props) {
  return (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationIcon
            className="h-5 w-5 text-red-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">Error</h3>
          <div className="mt-2 text-sm text-red-700">
            <p>{props.msg ? props.msg : "Se produjo un error al validar."}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

function App() {
  const [vista, setVista] = useState();
  const [errMsg, setErrMsg] = useState();
  const [data, setData] = useState();
  const [counters, setCounter] = useState();
  const [working, setWorking] = useState(false);

  useEffect(() => {
    setVista("INPUT");
  }, []);

  function inputHandler({ index, files, rg90 }) {
    if (index.length === 0) {
      alert("Indique la Columna del RUC");
      return;
    }

    if (files.length === 0) {
      alert("Cargue un archivo.");
      return;
    }

    setWorking(true);

    let file = files[0];

    const form = new FormData();
    form.append("index", index);
    form.append("rg90", rg90);
    form.append("file", file, file.name);

    const options = {
      method: "POST",
    };

    options.body = form;
    fetch("https://people.aguara.app/validate-ruc", options)
      .then(function (response) {
        if (!response.ok) {
          return Promise.reject(response.json());
        }
        return response.json();
      })
      .then(function (response) {
        setData(response.invalid_list);
        setCounter(response.counter);
        setVista("OUTPUT");
        setWorking(false);
      })
      .catch(function (err) {
        err.then(function (err) {
          setErrMsg(err.detail);
          setVista("ERROR");
          setWorking(false);
        });
      });
  }

  return (
    <div>
      <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
        <div className="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
          <div className="ml-4 mt-4">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {vista === "OUTPUT" || vista === "ERROR"
                ? "Resultado"
                : "Validador de RUC Online"}
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              {vista === "OUTPUT" || vista === "ERROR"
                ? "Listado de RUCs no válidos."
                : "Suba un archivo EXCEL, CSV o TXT y nos encargaremos de verificar la situación de los contribuyentes."}
            </p>
          </div>
          {vista === "OUTPUT" || vista === "ERROR" ? (
            <div className="ml-4 mt-4 flex-shrink-0">
              <button
                type="button"
                onClick={() => setVista("INPUT")}
                className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Validar Otro
              </button>
            </div>
          ) : (
            <span></span>
          )}
        </div>
      </div>
      <div
        className={
          vista === "OUTPUT"
            ? "w-full pl-4 pr-4 sm:pl-0 sm:pr-0 mt-6 sm:mt-5 space-y-6 sm:space-y-5"
            : "w-full pl-4 pr-4 sm:pl-0 sm:pr-0 sm:max-w-3xl sm:mx-auto mt-6 sm:mt-5 space-y-6 sm:space-y-5"
        }
      >
        {vista === "INPUT" ? (
          <InputElement handler={inputHandler} working={working}></InputElement>
        ) : (
          <span></span>
        )}
        {vista === "OUTPUT" ? (
          <OutputElement data={data} counters={counters}></OutputElement>
        ) : (
          <span></span>
        )}
        {vista === "ERROR" ? (
          <ErrorElement msg={errMsg}></ErrorElement>
        ) : (
          <span></span>
        )}
      </div>
    </div>
  );
}

export default App;
